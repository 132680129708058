import { createSlice } from "@reduxjs/toolkit";
import { usStates } from "./USStates";

export const journeyDetailsTitle = "Journey Details";
export const ivfAndMedication = "IVF and Medication";

const journeyDetails = {
  title: journeyDetailsTitle,
  description:
    "We are collecting this information in order to develop new products. These are optional and not required for you to seek loan options.",
  optional: true,
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="sm:w-4 sm:h-4 lg:w-6 lg:h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
      />
    </svg>
  ),
  questions: [
    {
      id: "amhLevel",
      label: "Do you know your AMH level? If so, please enter it.",
      value: NaN,
      placeholder: "AMH Level (ng/mL)",
      ariaLabel: "AMH level, in ng/mL",
      isRequired: false,
      type: "amhLevel",
    },
    {
      id: "heightInFeet",
      label: "Height (feet)",
      value: "",
      placeholder: "Height, in feet",
      arialLabel: "Height, in feet",
      isRequired: false,
      type: "number",
      gridId: 4,
      gridSize: 2,
      min: 0,
      max: 10,
    },
    {
      id: "heightInInches",
      label: "Height (in)",
      value: "",
      placeholder: "Height, in inches",
      arialLabel: "Height, in inches",
      isRequired: false,
      type: "number",
      gridId: 4,
      gridSize: 2,
      min: 0,
      max: 11,
    },
    {
      id: "weight",
      label: "Weight (lbs)",
      value: "",
      placeholder: "Weight, in pounds",
      ariaLabel: "Weight, in pounds",
      isRequired: false,
      type: "number",
      min: 0,
      max: 1000,
    },
    {
      id: "ivfReason",
      otherId: "ivfReasonOther",
      label: "IVF Reason (choose all that apply)",
      values: [],
      value: "",
      ariaLabel: "IVF Reason",
      isRequired: false,
      type: "ivfReason",
    },
    {
      id: "ownOrDonorEggs",
      label: "Do you plan to use your own eggs or donor eggs?",
      value: "",
      options: ["Own", "Donor", "Unsure"],
      ariaLabel:
        "Choose between whether you're using your own eggs or donor eggs.",
      isRequired: false,
    },
    {
      id: "howManyTimesUsedIvf",
      label:
        "How many times have you used IVF in the past? (include all cycles, even those not resulting in pregnancy)",
      value: "",
      type: "number",
      ariaLabel: "Tell us how many times you used IVF in the past.",
      placeholder:
        "Number of times you used IVF in the past - please include all cycles.",
      isRequired: false,
    },
    {
      id: "gravida",
      label: "How many prior pregnancies have you had?",
      value: "",
      type: "number",
      placeholder: "Number of prior pregnancies",
      ariaLabel: "Form field to enter in the number of pregnancies",
      isRequired: false,
    },
    {
      id: "priorFullTermBirths",
      label:
        "How many previous full-term births have you had? (Include twins, triplets, etc. as a single birth.)",
      value: "",
      type: "number",
      placeholder: "Number of previous full-term births",
      ariaLabel:
        "Form field to enter in the number of previous full-term births.",
      isRequired: false,
    },
  ],
};

export const defaultSteps = (user, { partner, myDashboardPage }) => [
  {
    title: "Personal Information",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="sm:w-4 sm:h-4 lg:w-6 lg:h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
        />
      </svg>
    ),
    questions: [
      {
        id: "firstName",
        label: "First Name",
        value: "",
        placeholder: "Your first name",
        ariaLabel: "Enter First Name",
        maxLength: 256,
      },
      {
        id: "lastName",
        label: "Last Name",
        value: "",
        placeholder: "Your last name",
        ariaLabel: "Enter Last Name",
        maxLength: 256,
      },
    ],
  },
  {
    title: "Loan Information",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        className="sm:w-4 sm:h-4 lg:w-6 lg:h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
        />
      </svg>
    ),
    questions: [
      {
        id: "loanTimeFrame",
        label: "How soon will you need the loan?",
        placeholder: "",
        value: "",
        options: ["ASAP", "3-6 months", "6+ months"],
        ariaLabel: "Select how soon you will need the loan",
      },
      {
        id: "loanAmount",
        label: "Desired loan size? ($1,000 - $50,000)",
        placeholder: "Your desired loan size",
        value: "",
        type: "currency",
        min: 1000,
        max: 50000,
        ariaLabel: "Enter your desired loan size",
      },
      {
        id: "telephone",
        label: "Your telephone?",
        value: "",
        placeholder: "Your telephone",
        type: "tel",
        ariaLabel: "Enter your telephone",
      },
      {
        id: "dateOfBirth",
        label: "Your birthdate?",
        value: "",
        placeholder: "Your birthdate",
        type: "date",
        ariaLabel: "Enter your birthdate",
      },
      {
        id: "address1",
        label: "What's your address?",
        value: "",
        placeholder: "Your address",
        gridId: 0,
        gridSize: 3,
        spansDouble: true,
        maxLength: 1024,
        ariaLabel: "Enter your address",
      },
      {
        id: "address2",
        label: "Line #2 (optional)",
        value: "",
        placeholder: "Line #2",
        gridId: 0,
        gridSize: 3,
        isRequired: false,
        maxLength: 1024,
        ariaLabel: "Enter your address line #2",
      },
      {
        id: "city",
        label: "Your City?",
        value: "",
        placeholder: "Your City",
        gridId: 1,
        gridSize: 3,
        maxLength: 256,
        ariaLabel: "Enter your city",
      },
      {
        id: "state",
        label: "Your State?",
        value: "",
        placeholder: "Your State",
        dropDownOptions: Object.keys(usStates).map((key) => key),
        gridId: 1,
        gridSize: 3,
        ariaLabel: "Enter your state",
      },
      {
        id: "zipCode",
        label: "Your zipcode?",
        value: "",
        placeholder: "Your zipcode",
        gridId: 1,
        gridSize: 3,
        // required for android chrome input validation
        type: window?.navigator?.userAgent?.toLowerCase()?.includes("android")
          ? "tel"
          : "number",
        maxLength: 5,
        ariaLabel: "Enter your zipcode",
      },
    ],
  },
  {
    title: "Additional & Credit Information",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="sm:w-4 sm:h-4 lg:w-6 lg:h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
        />
      </svg>
    ),
    questions: [
      {
        id: "householdAssets",
        label:
          "What are your household's liquid assets? (cash and investments, but not homes or vehicles)",
        value: "",
        placeholder: "Household assets?",
        options: [
          "$0 - $9,999",
          "$10,000 - $24,999",
          "$25,000 - $49,999",
          "$50,000 - $99,999",
          "$100,000 - $199,999",
          "$200,000 or more",
        ],
        ariaLabel: "What are your household assets?",
      },
      {
        id: "willingnessToMoveAssets",
        label:
          "In the future, would you be interested in moving a portion of your assets to another institution in order to qualify for better rates?",
        value: "",
        placeholder: "Willing to move assets?",
        options: ["Yes", "No", "Maybe"],
        ariaLabel:
          "Provide whether you would move your assets to a different institution for lower rates",
      },
      {
        id: "usResidencyStatus",
        label: "Are you a US Citizen?",
        value: "",
        placeholder: "Your US Citizen status",
        options: ["US Citizen", "Neither", "Permanent Resident"],
        ariaLabel: "Are you a US Citizen?",
      },
      {
        id: "pharmacy",
        type: "autocomplete",
        label: "Do you have a speciality fertility pharmacy? (optional)",
        value: partner?.type === "pharmacy" ? partner?.name : "",
        placeholder: "Pharmacy's name",
        isRequired: false,
        maxLength: 1024,
        ariaLabel: "Enter your pharmacy's name",
      },
      {
        id: "pharmacyCity",
        label: "Which city is your pharmacy? (optional)",
        value: partner?.type === "pharmacy" ? partner?.name : "",
        placeholder: "Pharmacy's city",
        isRequired: false,
        ariaLabel: "Enter your pharmacy's city",
        gridId: 2,
        gridSize: 2,
        maxLength: 256,
      },
      {
        id: "pharmacyState",
        label: "Which state is your pharmacy? (optional)",
        value: partner?.type === "pharmacy" ? partner?.name : "",
        placeholder: "Pharmacy's state",
        isRequired: false,
        ariaLabel: "Enter your pharmacy's state",
        gridId: 2,
        gridSize: 2,
        dropDownOptions: Object.keys(usStates).map((key) => key),
      },
      {
        id: "agency",
        type: "autocomplete",
        label: "Which Agency do you work with? (optional)",
        value: partner?.type === "agency" ? partner?.name : "",
        placeholder: "Surrogacy or Egg Donor Agency",
        isRequired: false,
        ariaLabel: "Which Agency do you work with?",
        gridId: myDashboardPage ? 3 : undefined,
        gridSize: 3,
        maxLength: 1024,
      },
      {
        id: "ivfClinic",
        type: "autocomplete",
        label: "Which IVF clinic do you work with? (optional)",
        value: partner?.type === "clinic" ? partner?.name : "",
        placeholder: "IVF clinic name",
        isRequired: false,
        ariaLabel: "Which IVF clinic do you work with?",
        gridId: myDashboardPage ? 3 : undefined,
        gridSize: 3,
        maxLength: 1024,
      },
      {
        id: "howDidYouHearAboutUs",
        label: "Where did you hear about us? (optional)",
        value: "",
        placeholder: "Please enter a name so we can thank them",
        ariaLabel: "Where did you hear about us?",
        isRequired: false,
        gridId: myDashboardPage ? 3 : undefined,
        gridSize: 3,
        maxLength: 2048,
      },
    ],
  },
  journeyDetails,
];

export const longFormSteps = (user, { partner, myDashboardPage }) => [
  {
    title: "Personal Information",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="sm:w-4 sm:h-4 lg:w-6 lg:h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
        />
      </svg>
    ),
    questions: [
      {
        id: "firstName",
        label: "First Name",
        value: "",
        placeholder: "Your first name",
        ariaLabel: "Enter First Name",
        maxLength: 256,
      },
      {
        id: "lastName",
        label: "Last Name",
        value: "",
        placeholder: "Your last name",
        ariaLabel: "Enter Last Name",
        maxLength: 256,
      },
    ],
  },
  {
    title: "Loan Information",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        className="sm:w-4 sm:h-4 lg:w-6 lg:h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
        />
      </svg>
    ),
    questions: [
      {
        id: "loanTimeFrame",
        label: "How soon will you need the loan?",
        placeholder: "",
        value: "",
        options: ["ASAP", "3-6 months", "6+ months"],
        ariaLabel: "Select how soon you will need the loan",
      },
      {
        id: "loanPurpose",
        label: "For what purpose?",
        placeholder: "",
        value: user?.loanPurpose ?? "",
        options: [
          ivfAndMedication,
          "Surrogacy",
          "Egg Freezing",
          "Adoption",
          "Egg donor",
          "Other",
        ],
        ariaLabel: "Select a loan purpose",
      },
      {
        id: "loanAmount",
        label: "Desired loan size? ($1,000 - $50,000)",
        placeholder: "Your desired loan size",
        value: "",
        type: "currency",
        min: 1000,
        max: 50000,
        ariaLabel: "Enter your desired loan size",
      },
      {
        id: "employmentStatus",
        label: "What's your employment status?",
        placeholder: "Your employment status",
        value: "",
        options: [
          "Employed Full Time",
          "Employed Part Time",
          "Military",
          "Not Employed",
          "Self Employed",
          "Retired",
          "Other",
        ],
        ariaLabel: "Select a employment status",
      },
      {
        id: "highestLevelOfEducation",
        label: "What's your highest level of education?",
        placeholder: "Your highest level of education?",
        value: "",
        options: [
          "High School",
          "Associate",
          "Bachelors",
          "Masters",
          "Doctorate",
          "Certificate",
          "Other",
        ],
        ariaLabel: "Select highest level of education?",
      },
      {
        id: "incomeFrequency",
        label: "How often do you get paid?",
        placeholder: "How often do you get paid",
        value: "",
        options: [
          "Once every week",
          "Once every other week",
          "Twice per month",
          "Once per month",
        ],
        ariaLabel: "How often do you get paid?",
      },
    ],
  },
  {
    title: "Additional Personal Information",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="sm:w-4 sm:h-4 lg:w-6 lg:h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
        />
      </svg>
    ),
    questions: [
      {
        id: "telephone",
        label: "Your telephone?",
        value: "",
        placeholder: "Your telephone",
        type: "tel",
        ariaLabel: "Enter your telephone",
      },
      {
        id: "dateOfBirth",
        label: "Your birthdate?",
        value: "",
        placeholder: "Your birthdate",
        type: "date",
        ariaLabel: "Enter your birthdate",
      },
      {
        id: "address1",
        label: "What's your address?",
        value: "",
        placeholder: "Your address",
        gridId: 0,
        gridSize: 3,
        spansDouble: true,
        ariaLabel: "Enter your address",
        maxLength: 1024,
      },
      {
        id: "address2",
        label: "Line #2 (optional)",
        value: "",
        placeholder: "Line #2",
        gridId: 0,
        gridSize: 3,
        isRequired: false,
        ariaLabel: "Enter your address line #2",
        maxLength: 1024,
      },
      {
        id: "city",
        label: "Your City?",
        value: "",
        placeholder: "Your City",
        gridId: 1,
        gridSize: 3,
        ariaLabel: "Enter your city",
        maxLength: 256,
      },
      {
        id: "state",
        label: "Your State?",
        value: "",
        placeholder: "Your State",
        dropDownOptions: Object.keys(usStates).map((key) => key),
        gridId: 1,
        gridSize: 3,
        ariaLabel: "Enter your state",
      },
      {
        id: "zipCode",
        label: "Your zipcode?",
        value: "",
        placeholder: "Your zipcode",
        gridId: 1,
        gridSize: 3,
        // required for android chrome input validation
        type: window?.navigator?.userAgent?.toLowerCase()?.includes("android")
          ? "tel"
          : "number",
        maxLength: 5,
        ariaLabel: "Enter your zipcode",
      },
      {
        id: "yearsThereZip",
        label: "Years there?",
        value: "",
        placeholder: "Years there",
        type: "number",
        ariaLabel: "Enter years there?",
      },
      {
        id: "ownOrRent",
        label: "Primary residency status?",
        value: "",
        placeholder: "Own or Rent?",
        options: ["Own", "Own with Mortgage", "Rent"],
        ariaLabel: "Select primary residency status?",
      },
    ],
  },
  {
    title: "Additional & Credit Information",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="sm:w-4 sm:h-4 lg:w-6 lg:h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
        />
      </svg>
    ),
    questions: [
      {
        id: "creditScore",
        label: "What's your estimated credit score?",
        placeholder: "Your estimated credit score",
        value: "",
        options: ["Under 620", "620-659", "660-679", "680-719", "720+"],
        ariaLabel: "Select estimated credit score?",
      },
      {
        id: "individualIncome",
        label: (
          <>
            What is your annual <strong>individual</strong> income?
          </>
        ),
        placeholder: "Your individual income?",
        value: "",
        type: "currency",
        ariaLabel: "Enter annual individual income",
      },
      {
        id: "householdIncome",
        label: (
          <>
            What is your annual <strong>household</strong> income? (optional)
          </>
        ),
        placeholder: "Your household income?",
        value: "",
        type: "currency",
        isRequired: false,
        ariaLabel: "Enter annual household income",
      },
      {
        id: "householdAssets",
        label:
          "What are your household's liquid assets? (cash and investments, but not homes or vehicles)",
        value: "",
        placeholder: "Household assets?",
        options: [
          "$0 - $9,999",
          "$10,000 - $24,999",
          "$25,000 - $49,999",
          "$50,000 - $99,999",
          "$100,000 - $199,999",
          "$200,000 or more",
        ],
        ariaLabel: "What are your household assets?",
      },
      {
        id: "willingnessToMoveAssets",
        label:
          "In the future, would you be interested in moving a portion of your assets to another institution in order to qualify for better rates?",
        value: "",
        placeholder: "Willing to move assets?",
        options: ["Yes", "No", "Maybe"],
        ariaLabel:
          "Provide whether you would move your assets to a different institution for lower rates",
      },
      {
        id: "usResidencyStatus",
        label: "Are you a US Citizen?",
        value: "",
        placeholder: "Your US Citizen status",
        options: ["US Citizen", "Neither", "Permanent Resident"],
        ariaLabel: "Are you a US Citizen?",
      },
      {
        id: "pharmacy",
        type: "autocomplete",
        label: "Do you have a speciality fertility pharmacy? (optional)",
        value: partner?.type === "pharmacy" ? partner?.name : "",
        placeholder: "Pharmacy's name",
        isRequired: false,
        ariaLabel: "Enter your pharmacy's name",
        maxLength: 1024,
      },
      {
        id: "pharmacyCity",
        label: "Which city is your pharmacy? (optional)",
        value: partner?.type === "pharmacy" ? partner?.name : "",
        placeholder: "Pharmacy's city",
        isRequired: false,
        ariaLabel: "Enter your pharmacy's city",
        gridId: 2,
        gridSize: 2,
        maxLength: 256,
      },
      {
        id: "pharmacyState",
        label: "Which state is your pharmacy? (optional)",
        value: partner?.type === "pharmacy" ? partner?.name : "",
        placeholder: "Pharmacy's state",
        isRequired: false,
        ariaLabel: "Enter your pharmacy's state",
        gridId: 2,
        gridSize: 2,
        dropDownOptions: Object.keys(usStates).map((key) => key),
      },
      {
        id: "agency",
        type: "autocomplete",
        label: "Which Agency do you work with? (optional)",
        value: partner?.type === "agency" ? partner?.name : "",
        placeholder: "Surrogacy or Egg Donor Agency",
        isRequired: false,
        ariaLabel: "Which Agency do you work with?",
        gridId: myDashboardPage ? 3 : undefined,
        gridSize: 3,
        maxLength: 1024,
      },
      {
        id: "ivfClinic",
        type: "autocomplete",
        label: "Which IVF clinic do you work with? (optional)",
        value: partner?.type === "clinic" ? partner?.name : "",
        placeholder: "IVF clinic name",
        isRequired: false,
        ariaLabel: "Which IVF clinic do you work with?",
        gridId: myDashboardPage ? 3 : undefined,
        gridSize: 3,
        maxLength: 1024,
      },
      {
        id: "howDidYouHearAboutUs",
        label: "Where did you hear about us? (optional)",
        value: "",
        placeholder: "Please enter a name so we can thank them",
        ariaLabel: "Where did you hear about us?",
        isRequired: false,
        gridId: myDashboardPage ? 3 : undefined,
        gridSize: 3,
        maxLength: 2048,
      },
    ],
  },
  journeyDetails,
];

export const myDashboardSlice = createSlice({
  name: "myDashboardSlice",
  initialState: {
    accountInfoFormCompleted: false,
    agreedToMarketing: false,
    isDashboardVisible: false,
    currentStep: 0,
    showJourneyDetails: false,
  },
  reducers: {
    setAccountInfoFormCompleted: (state, action) => {
      state.accountInfoFormCompleted = action.payload;
    },
    setDashboardVisible: (state, action) => {
      state.isDashboardVisible = action.payload;
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setShowJourneyDetails: (state, action) => {
      state.showJourneyDetails = action.payload;
    },
  },
});

export const {
  setAccountInfoFormCompleted,
  setDashboardVisible,
  setCurrentStep,
  setShowJourneyDetails,
} = myDashboardSlice.actions;

export default myDashboardSlice.reducer;
